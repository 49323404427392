<template>
    <div class="home">
        <h4 class="section-title p-l-10">
            Dashboard
        </h4>
        <!-- <h5 class="title mb-4">
            <small>
                Welcome to gewaer admin panel
            </small>
        </h5> -->

        <div class="row section">
            <div v-for="stat in stats" :key="stat.name" class="col-4 col-xl">
                <div class="card stat-card">
                    <header class="stat-card__header d-flex justify-content-between">
                        <h5 class="stat-card__title">
                            {{ stat.title }}
                        </h5>
                        <i class="fa fa-info-circle" />
                    </header>
                    <article class="stat-card__content w-100 text-center number" :class="stat.colorClass">
                        {{ stat.count }}
                    </article>
                </div>
            </div>
        </div>

        <div class="row section">
            <div class="col-md-6">
                <div class="card stat-card">
                    <header class="stat-card__header d-flex justify-content-between">
                        <h5 class="stat-card__title">
                            Leads Overview
                        </h5>
                    </header>
                    <article class="stat-card__content w-100 text-center">
                        <ve-histogram :data="chartData" />
                    </article>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card stat-card">
                    <header class="stat-card__header d-flex justify-content-between">
                        <h5 class="stat-card__title">
                            Need Help? Contact your agent manager
                        </h5>
                    </header>
                    <article class="stat-card__content w-100 mt-4">
                        <p> This is your agent manager information</p>
                        <ul>
                            <li>Name</li>
                            <li>Email</li>
                            <li>Phone Number</li>
                        </ul>
                    </article>
                </div>

                <div class="card stat-card">
                    <header class="stat-card__header d-flex justify-content-between">
                        <h5 class="stat-card__title">
                            Landing Page link
                        </h5>
                    </header>
                    <article class="stat-card__content w-100">
                        <p> This is the link to your landing page. Give this link to possible leads: </p>
                        <p> link </p>
                        <div class="w-100 text-right">
                            <button class="btn btn-primary">
                                Visit your landing page
                            </button>
                        </div>
                    </article>
                    <hr>
                    <header class="stat-card__header d-flex justify-content-between">
                        <h5 class="stat-card__title">
                            Sponsor other Agents
                        </h5>
                    </header>
                    <article class="stat-card__content w-100">
                        <p> This is the link to the agent registration that links you as the sponsor. Give this link to people you thin could be good agents just like you: </p>
                        <p> link </p>
                        <div class="w-100 text-right">
                            <button class="btn btn-success">
                                Visit your landing page
                            </button>
                        </div>
                    </article>
                </div>
            </div>
        </div>

        <welcome-section />
    </div>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
            chartData: {
                columns: ["date", "created", "closed"],
                rows: [{
                    "date": new Date().getFullYear(),
                    "created": 25,
                    closed: 0
                }]
            },
            stats: [{
                name: "created",
                title: "Leads Created",
                count: 33,
                colorClass: "text-info"
            },
            {
                name: "closed",
                title: "Leads Closed",
                count: 0,
                colorClass: "text-danger"
            },
            {
                name: "sponsored",
                title: "Agents Sponsored",
                count: 6,
                colorClass: "text-success"
            }]
        }
    },
    mounted() {
        this.getLeadsStats();
    },
    methods: {
        getLeadsStats() {
            axios.get('/leads-stats')
            .then(response => {
                this.stats = response.data;
            }).catch(error => {
                
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.home {
    .stat-card {

        &_header,
        &__title,
        i {
            color: #777 !important;
            font-weight: bold;
        }

        &__content.number {
            font-weight: bolder;
            font-size: 30px;
        }

    }
}
</style>
